<template>
  <div class="users-table">

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Email</th>
          <th>名前</th>
          <th>役割</th>
          <th>作成日時</th>
          <th>更新日時</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" @click="userClicked(user)">
          <td>{{ user.id }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.role }}</td>
          <td>{{ dateFormat(user.created_at) }}</td>
          <td>{{ dateFormat(user.updated_at) }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
export default {
  props: [
    'users'
  ],
  methods: {
    dateFormat(date) {
      return date.split('.')[0].replace('T', ' ')
    },
    userClicked(user) {
      this.$emit('user-clicked', user)
    }
  }
}
</script>


<style scoped lang="scss">
.users-table {
  position: relative;
  width: 100%;
  height: 100%;

  table {
    position: relative;
    width: 100%;
    border-spacing: 0;
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: #FCE4EC;
      }
    }
    th {
      position: sticky;
      top: 0;
      text-align: left;
      padding: 8px 16px;
      font-size: 14px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      background-color: white;

    }
    td {
      padding: 8px 16px;
      font-size: 14px;
      white-space: pre-line;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

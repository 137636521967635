<template>
  <div class="sign-in">
    <div class="sign-in-container">
      <div class="sign-in-content">
        <h1>Koetatsu Console</h1>

        <v-form @submit="onSubmit">

          <div class="a-row">
            <v-alert :value="errors != null" transition="scale-transition">
              <ul><li v-for="e in errors">{{ e }}</li></ul>
            </v-alert>
          </div>

          <div class="a-row">
            <v-text-field
              v-model="email"
              label="EMAIL"
              type="email"
              clearable
              dark
              hide-details
            />
          </div>

          <div class="a-row">
            <v-text-field
              v-model="password"
              label="PASSWORD"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
              dark
              hide-details
            />
          </div>

          <div class="a-row">
            <v-btn large depressed type="submit" class="sign-in-button">Sign in</v-btn>
          </div>

        </v-form>

        <div>
          <a @click="$router.push('/forgot_password')">Forgot your password?</a>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Common from './common.js'

export default {
  data() {
    return {
      showPassword: false,
      email: Common.email(),
      password: '',
      errors: null
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      this.errors = null
      let params = {
        email: this.email,
        password: this.password
      }
      this.$root.showActivityView()
      Common.axios(document).post('/auth/sign_in', params).then(response => {
        Common.saveEmail(this.email)
        Common.saveAccessToken(response.headers['access-token'])
        Common.saveUid(response.headers['uid'])
        Common.saveClient(response.headers['client'])
        Common.saveRole(response.data.data.role)
        this.$router.push('/')
        this.$root.hideActivityView()
      }).catch(error => {
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.sign-in {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E91E63;
  color: white;

  .sign-in-container {
    width: 65%;
    margin: 0 0 64px;
    min-width: 360px;

    .sign-in-content {
      width: 100%;
      max-width: 360px;
      padding: 16px;

      .a-row {
        margin: 32px 0;
        .sign-in-button {
          width: 200px;
          color: #E91E63;
        }
      }

      a {
        color: white;
        &:hover, &:visited {
          color: white;
        }
      }
    }
  }
}
</style>

<template>
  <div class="page">

    <v-navigation-drawer class="drawer" dark permanent app width="320">
      <v-list>
        <v-list-item v-for="item in items" :key="item.title" @click="itemClicked(item)">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="page-content">
      <slot />
    </div>

    <password-dialog ref="aPasswordDialog" />

  </div>
</template>

<script>
import PasswordDialog from './password_dialog.vue'

export default {
  components: {
    'password-dialog': PasswordDialog
  },
  data() {
    return {
      items: [
        { title: 'Dashboard', icon: 'mdi-view-dashboard', path: '/contacts' },
        { title: 'Contacts', icon: 'list', path: '/contacts' },
        { title: 'Exports', icon: 'list', path: '/exports' },
        { title: 'Settings', icon: 'settings', path: '/settings' },
        { title: 'Users', icon: 'people', path: '/users' },
        { title: 'パスワード変更', icon: 'password', path: '/password' },
        { title: 'サインアウト', icon: 'logout', path: '/sign_out' }
      ]
    }
  },
  methods: {
    itemClicked(item) {
      if (this.$route.path == item.path) {
        return
      }
      if (item.path == '/password') {
        this.updatePassword()
        return
      }
      if (item.path == '/sign_out') {
        this.signOut()
        return
      }
      this.$router.push(item.path)
    },
    updatePassword() {
      this.$refs.aPasswordDialog.showDialog()
    },
    signOut() {
      Common.saveEmail('')
      Common.saveAccessToken('')
      Common.saveUid('')
      Common.saveRole('')
      Common.saveClient('')
      Common.axios(document).delete('/auth/sign_out').then(response => {
        location.reload()
      }).catch(error => {
        console.log(error)
        location.reload()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  width: 100%;
  min-width: 1024px;

  .drawer {
    background-color: #E91E63;
  }

  .page-content {
    margin: 0 0 0 320px;
  }
}
</style>

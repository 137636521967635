<template>
  <v-app>
    <router-view v-show="showPage"></router-view>
    <activity-view ref="aActivityView" />
  </v-app>
</template>

<script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import Common from './common.js'
import ActivityView from './activity_view.vue'

import Contacts from './contacts.vue'
import Exports from './exports.vue'
import Settings from './settings.vue'
import Users from './users.vue'
import SignIn from './sign_in.vue'
import ForgotPassword from './forgot_password.vue'
import ResetPassword from './reset_password.vue'
import Home from './home.vue'

Vue.use(VueRouter)
Vue.use(Vuetify)

const vuetify = new Vuetify({
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#E91E63'
      }
    }
  }
})

let router = new VueRouter({
  routes: [
    { path: '/contacts', component: Contacts },
    { path: '/exports', component: Exports },
    { path: '/settings', component: Settings },
    { path: '/users', component: Users },
    { path: '/sign_in', component: SignIn },
    { path: '/forgot_password', component: ForgotPassword },
    { path: '/reset_password', component: ResetPassword },
    { path: '/', component: Home }
  ]
})

export default {
  router,
  vuetify,
  components: {
    'activity-view': ActivityView
  },
  data() {
    return {
      tokenValidated: false
    }
  },
  computed: {
    showPage() {
      if (this.tokenValidated) {
        return true
      }
      if (['/reset_password', '/forgot_password', '/sign_in'].includes(this.$route.path)) {
        return true
      }
      return false
    }
  },
  watch: {
    '$route' (to, from) {
      if (!['/reset_password', '/forgot_password', '/sign_in'].includes(to.path)) {
        this.validateToken()
      }
    }
  },
  methods: {
    validateToken() {
      if (!Common.accessToken()) {
        this.$router.push('/sign_in')
        return
      }
      let params = {
        uid: Common.uid(),
        client: Common.client(),
        'access-token': Common.accessToken()
      }
      this.showActivityView()
      Common.axios(document).get('/auth/validate_token', params).then(response => {
        this.tokenValidated = true
        this.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.hideActivityView()
        if (401 == error.response.status) {
          this.$router.push('/sign_in')
          this.tokenValidated = true
        }
      })
    },
    showActivityView() {
      this.$refs.aActivityView.showView()
    },
    hideActivityView() {
      this.$refs.aActivityView.hideView()
    }
  },
  mounted() {
    if (!['/reset_password', '/forgot_password', '/sign_in'].includes(this.$route.path)) {
      this.validateToken()
    }
  }
}
</script>

<style>
body {
  width: 100%;
  height: 100%;
}
</style>

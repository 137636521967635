<template>
  <div class="exports-table">

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>状態</th>
          <th>更新件数</th>
          <th>作成日時</th>
          <th>更新日時</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="e in exports">
          <td>{{ e.id }}</td>
          <td>{{ stateText(e.state) }}</td>
          <td>{{ e.n_contacts }}</td>
          <td>{{ dateFormat(e.created_at) }}</td>
          <td>{{ dateFormat(e.updated_at) }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
export default {
  props: [
    'exports'
  ],
  methods: {
    stateText(state) {
      return ['実行まち', '実行中', '成功', '失敗', '他のAPI実行中によりキャンセル'][state]
    },
    dateFormat(date) {
      return date.split('.')[0].replace('T', ' ')
    }
  }
}
</script>


<style scoped lang="scss">
.exports-table {
  position: relative;
  width: 100%;
  height: 100%;

  table {
    position: relative;
    width: 100%;
    border-spacing: 0;
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: #FCE4EC;
      }
    }
    th {
      position: sticky;
      top: 0;
      text-align: left;
      padding: 8px 16px;
      font-size: 14px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      background-color: white;

    }
    td {
      padding: 8px 16px;
      font-size: 14px;
      white-space: pre-line;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

<template>
  <v-dialog v-model="show" max-width="480px" width="100%" persistent scrollable>
    <v-card class="user-dialog" v-if="user">

      <v-card-title>ID: {{ user.id }}, Email: {{ user.email }}</v-card-title>

      <v-card-text>

        <v-alert :value="errors != null" transition="scale-transition" color="primary" dark>
          <ul><li v-for="e in errors">{{ e }}</li></ul>
        </v-alert>

        <div class="a-row">
          <v-text-field label="名前" v-model="name" hide-details clearable />
        </div>

        <div class="a-row">
          <v-select label="役割" v-model="role" :items="['admin', 'user']" hide-details />
        </div>

        <div class="a-row checkbox">
          <v-checkbox v-model="updatePassword" label="パスワードを変更する" />
        </div>

        <div class="a-row password" v-if="updatePassword">
          <v-text-field
            label="新しいパスワード"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
            hide-details
          />
        </div>

        <div class="a-row" v-if="updatePassword">
          <v-text-field
            label="新しいパスワードの確認"
            v-model="passwordConfirmation"
            :type="showPasswordConfirmation ? 'text' : 'password'"
            :append-icon="showPasswordConfirmation ? 'visibility' : 'visibility_off'"
            @click:append="showPasswordConfirmation = !showPasswordConfirmation"
            hide-details
          />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-btn depressed @click="deleteButtonClicked" class="delete-button"><v-icon>delete</v-icon>削除</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed @click="cancelButtonClicked"><v-icon>cancel</v-icon>CANCEL</v-btn>
        <v-btn depressed color="primary" @click="updateButtonClicked" class="update-button"><v-icon>save</v-icon>更新</v-btn>
      </v-card-actions>
    </v-card>

    <message-dialog ref="aMessageDialog" @ok-button-clicked="okButtonClicked" />

  </v-dialog>
</template>

<script>
import Common from './common.js'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'message-dialog': MessageDialog
  },
  props: [
    'width'
  ],
  data() {
    return {
      show: false,
      errors: null,
      user: null,
      name: null,
      role: null,
      updatePassword: false,
      password: null,
      passwordConfirmation: null,
      showPassword: false,
      showPasswordConfirmation: false,
      tagUpdate: 1,
      tagDelete: 2,
      tagDeleted: 3
    }
  },
  methods: {
    showDialog(user) {
      this.user = user
      this.name = user.name
      this.role = user.role
      this.updatePassword = false
      this.password = null
      this.passwordConfirmation = null
      this.show = true
    },
    cancelButtonClicked() {
      this.show = false
    },
    okButtonClicked(tag) {
      if (tag == this.tagUpdate || tag == this.tagDeleted) {
        this.show = false
        return
      }
      if (tag == this.tagDelete) {
        this.deleteUser()
        return
      }
    },
    updateButtonClicked() {
      this.updateUser()
    },
    deleteButtonClicked() {
      this.$refs.aMessageDialog.showDialog('Koetatsu Console', '削除してよろしいですか?', this.tagDelete)
    },
    updateUser() {
      this.errors = null
      let params = {
        user: {
          id: this.user.id,
          email: this.user.email,
          name: this.name,
          role: this.role
        }
      }
      if (this.updatePassword) {
        params.user.password = this.password
        params.user.password_confirmation = this.passwordConfirmation
      }
      this.$root.showActivityView()
      Common.axios(document).patch(`/console/users/${this.user.id}`, params).then(response => {
        console.log(response)
        this.$root.hideActivityView()
        this.$refs.aMessageDialog.showDialog('Koetatsu Console', '変更しました', this.tagUpdate)
        this.$emit('user-did-update')
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    },
    deleteUser() {
      this.errors = null
      this.$root.showActivityView()
      Common.axios(document).delete(`/console/users/${this.user.id}`).then(response => {
        console.log(response)
        this.$root.hideActivityView()
        this.$refs.aMessageDialog.showDialog('Koetatsu Console', '削除しました', this.tagDeleted)
        this.$emit('user-did-update')
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.user-dialog {
  .a-row {
    margin: 16px 0 32px;
    &.checkbox {
      margin: 48px 0 0;
    }
    &.password {
      margin: 0 0 32px;
    }
  }
  .update-button {
    width: 96px;
  }
}
</style>

<template>
  <v-dialog v-model="show" width="800px" persistent scrollable>
    <v-card class="contact-dialog">

      <v-card-title>Contact</v-card-title>

      <v-card-text>
        <table v-if="contact">
          <tr v-for="attr in contactAttrs">
            <th>{{ attr.title }}</th>
            <td>{{ attr.value }}</td>
          </tr>
        </table>
      </v-card-text>

      <v-card-actions>
        <v-btn depressed @click="updateButtonClicked"color="#E91E63" dark v-if="contact && contact.state != 0">
          状態を"エクスポートまち"に変更
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed @click="okButtonClicked" color="#E91E63" dark>OK</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  props: [
    'width'
  ],
  data() {
    return {
      show: false,
      contact: null
    }
  },
  computed: {
    contactAttrs() {
      return [
        { title: 'ID', value: this.contact.id },
        { title: '作成日時', value: this.contact.created_at },
        { title: '状態', value: this.contact.state == 0 ? 'エクスポートまち' : 'エクスポート済み' },

        { title: '会社名', value: this.contact.company },
        { title: '部署名', value: this.contact.division },
        { title: '姓',     value: this.contact.name1 },
        { title: '名',     value: this.contact.name2 },
        { title: 'セイ',   value: this.contact.kana1 },
        { title: 'メイ',   value: this.contact.kana2 },

        { title: 'PCメールアドレス', value: this.contact.email },
        { title: 'PCメールアドレス(確認)', value: this.contact.email_confirmation },

        { title: '電話番号1', value: this.contact.phone1 },
        { title: '電話番号2', value: this.contact.phone2 },
        { title: '電話番号3', value: this.contact.phone3 },
        { title: '郵便番号1', value: this.contact.zip1 },
        { title: '郵便番号2', value: this.contact.zip2 },
        { title: '都道府県',  value: this.contact.pref },

        { title: '市区町村・番地',         value: this.contact.street },
        { title: '建物・マンション名以降', value: this.contact.building },

        { title: '原稿の文字数', value: this.contact.text_length },
        { title: 'あてはまらない方の文字数', value: this.contact.text_length_other },
        { title: '外国語ナレーションの場合', value: this.contact.text_length_english },

        { title: '希望納期(年)',   value: this.contact.delivery_year },
        { title: '希望納期(月)',   value: this.contact.delivery_month },
        { title: '希望納期(日)',   value: this.contact.delivery_day },
        { title: '確定原稿送付日', value: this.contact.delivery_date2 },

        { title: '納品ファイル形式',         value: this.contact.file_format },
        { title: '納品ファイル形式(その他)', value: this.contact.file_format_other },

        { title: '利用用途',   value: this.contact.purpose_text },
        { title: 'CM放送媒体', value: this.contact.cm_text },

        { title: '追加オプション', value: this.contact.option_service },

        { title: 'ナレーター',           value: this.contact.narrator },
        { title: 'ナレーターお任せ',     value: this.contact.narrator_rely },
        { title: '英語ナレーション希望', value: this.contact.narrator_english },

        { title: '声のトーン',         value: this.contact.voice_image },
        { title: '声のトーン(その他)', value: this.contact.voice_image_other },

        { title: '読み上げスピード',         value: this.contact.voice_speed },
        { title: '読み上げスピード(その他)', value: this.contact.voice_speed_other },

        { title: '請求書送付について', value: this.contact.how_to_sending },
        { title: 'その他',             value: this.contact.other_text },
        { title: '知ったきっかけ',     value: this.contact.how_did_you_find },
        { title: '検索ワード',         value: this.contact.search_word }
      ]
    }
  },
  methods: {
    showDialog(contact) {
      this.contact = contact
      this.show = true
    },
    okButtonClicked() {
      this.show = false
    },
    updateButtonClicked() {
      this.$emit('update-button-clicked', this.contact)
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.contact-dialog {
  table {
    tr {
      border-botton: solid 1px rgba(0, 0, 0, 0.2);
      th {
        width: 30%;
        text-align: right;
        padding: 8px;
      }
      td {
        padding: 8px;
        white-space: pre-line;
      }
    }
  }
}
</style>

<template>
  <page>
    <div class="exports">
      <div class="a-header">

        <div class="a-col">
          <h1>Exports</h1>
          <v-btn depressed dark color="#E91E63" class="run-button" @click="runButtonClicked">いますぐ実行</v-btn>
        </div>
        <div v-if="pagination">
          <v-pagination v-model="page" :length="pagination.total_pages" total-visible="6" />
        </div>
      </div>

      <exports-table :exports="exports" />

      <message-dialog ref="aMessageDialog" @ok-button-clicked="okButtonClicked" />

    </div>
  </page>
</template>

<script>
import Common from './common.js'
import Page from './page.vue'
import ExportsTable from './exports_table.vue'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'page': Page,
    'exports-table': ExportsTable,
    'message-dialog': MessageDialog
  },
  data() {
    return {
      exports: null,
      pagination: null
    }
  },
  computed: {
    page: {
      get() {
        if (!this.pagination) {
          return 1
        }
        return this.pagination.page
      },
      set(val) {
        this.getExports(val, null)
      }
    }
  },
  methods: {
    runButtonClicked() {
      this.postExports()
    },
    okButtonClicked() {
      let lastId = 0 < this.exports.length ? this.exports[0].id : 0
      this.checkUpdate(lastId)
    },
    getExports(page, callback) {
      let params = {
        page: page
      }
      if (!this.callback) {
        this.$root.showActivityView()
      }
      Common.axios(document).get('/console/exports', { params: params }).then(response => {
        console.log(response)
        this.exports = response.data.exports
        this.pagination = response.data.pagination
        this.$root.hideActivityView()

        if (callback) {
          callback()
        }

      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    postExports() {
      this.$root.showActivityView()
      Common.axios(document).post('/console/exports').then(response => {
        this.$root.hideActivityView()
        this.$refs.aMessageDialog.showDialog('Koetatsu Console', '実行しました。')
      }).catch(error => {
        console.log(error)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    },
    checkUpdate(lastId) {
      this.getExports(1, () => {
        if (0 < this.exports.length && this.exports[0].id != lastId && 1 < this.exports[0].state) {
          return
        }
        setTimeout(() => {
          this.checkUpdate(lastId)
        }, 5000)
      })
    }
  },
  mounted() {
    this.getExports(1, null)
  }
}
</script>

<style scoped lang="scss">
.exports {
  .a-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .a-col {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        margin: 0 32px 0 0;
      }
    }
  }
}
</style>

<template>
  <page>
    <div class="contacts">
      <div class="a-header">
        <h1>Contacts</h1>
        <v-pagination v-model="page" :length="pagination.total_pages" total-visible="6" v-if="pagination" />
      </div>

      <contacts-table :contacts="contacts" @contact-clicked="contactClicked" />

      <contact-dialog ref="aContactDialog" @update-button-clicked="updateButtonClicked" />
      <message-dialog ref="aMessageDialog" @ok-button-clicked="okButtonClicked" />

    </div>
  </page>
</template>

<script>
import Common from './common.js'
import Page from './page.vue'
import ContactsTable from './contacts_table.vue'
import ContactDialog from './contact_dialog.vue'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'page': Page,
    'contacts-table': ContactsTable,
    'contact-dialog': ContactDialog,
    'message-dialog': MessageDialog
  },
  data() {
    return {
      contacts: null,
      pagination: null
    }
  },
  computed: {
    page: {
      get() {
        if (!this.pagination) {
          return 1
        }
        return this.pagination.page
      },
      set(val) {
        this.getContacts(val)
      }
    }
  },
  methods: {
    contactClicked(contact) {
      this.$refs.aContactDialog.showDialog(contact)
    },
    updateButtonClicked(contact) {
      this.updateContact(contact)
    },
    okButtonClicked() {
      this.getContacts(this.page)
    },
    getContacts(page) {
      let params = {
        page: page
      }
      this.$root.showActivityView()
      Common.axios(document).get('/console/contacts', params).then(response => {

        console.log(response)
        this.contacts = response.data.contacts
        this.pagination = response.data.pagination

        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    updateContact(contact) {
      contact.state = 0
      let params = {
        contact: contact
      }
      this.$root.showActivityView()
      Common.axios(document).patch(`/console/contacts/${contact.id}`, params).then(response => {

        console.log(response)
        /* this.contacts = response.data.contacts
         * this.pagination = response.data.pagination */
        let message = "更新しました!\nすぐに使用する場合、Exports画面でエクスポートしてください"
        this.$refs.aMessageDialog.showDialog('Koetatsu Console', message)

        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })

    }
  },
  mounted() {
    this.getContacts(1)
  }
}
</script>

<style scoped lang="scss">
.contacts {
  .a-header {
    padding: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>

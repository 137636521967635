<template>
  <div class="contacts-table">

    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>状態</th>
          <th>会社名</th>
          <th>部署</th>
          <th>名前</th>
          <th>カナ</th>
          <th>作成日時</th>
          <th>更新日時</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contact in contacts" @click="contactClicked(contact)">
          <td>{{ contact.id }}</td>
          <td>{{ stateText(contact.state) }}</td>
          <td>{{ contact.company }}</td>
          <td>{{ contact.division }}</td>
          <td>{{ `${contact.name1} ${contact.name2}` }}</td>
          <td>{{ `${contact.kana1} ${contact.kana2}` }}</td>
          <td>{{ dateFormat(contact.created_at) }}</td>
          <td>{{ dateFormat(contact.updated_at) }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
export default {
  props: [
    'contacts'
  ],
  methods: {
    stateText(state) {
      return ['エクスポートまち', 'エクスポート済み'][state]
    },
    dateFormat(date) {
      return date.split('.')[0].replace('T', ' ')
    },
    contactClicked(contact) {
      this.$emit('contact-clicked', contact)
    }
  }
}
</script>


<style scoped lang="scss">
.contacts-table {
  position: relative;
  width: 100%;
  height: 100%;

  table {
    position: relative;
    width: 100%;
    border-spacing: 0;
    tr {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      &:hover {
        background-color: #FCE4EC;
      }
    }
    th {
      position: sticky;
      top: 0;
      text-align: left;
      padding: 8px 16px;
      font-size: 14px;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      background-color: white;

    }
    td {
      padding: 8px 16px;
      font-size: 14px;
      white-space: pre-line;
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>

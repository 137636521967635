<template>
  <v-dialog v-model="show" max-width="360px" width="100%">
    <v-card class="password-dialog">

      <v-card-title>パスワード変更</v-card-title>

      <v-card-text>

        <v-alert :value="errors != null" transition="scale-transition" color="primary" dark>
          <ul><li v-for="e in errors">{{ e }}</li></ul>
        </v-alert>

        <div class="a-row">
          <v-text-field
            label="新しいパスワード"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
            hide-details
          />
        </div>

        <div class="a-row">
          <v-text-field
            label="新しいパスワードの確認"
            v-model="passwordConfirmation"
            :type="showPasswordConfirmation ? 'text' : 'password'"
            :append-icon="showPasswordConfirmation ? 'visibility' : 'visibility_off'"
            @click:append="showPasswordConfirmation = !showPasswordConfirmation"
            hide-details
          />
        </div>

      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn depressed @click="cancelButtonClicked"><v-icon>cancel</v-icon>CANCEL</v-btn>
        <v-btn depressed color="primary" @click="updateButtonClicked" class="update-button"><v-icon>save</v-icon>更新</v-btn>
      </v-card-actions>

    </v-card>

    <message-dialog ref="aMessageDialog" @ok-button-clicked="okButtonClicked" />

  </v-dialog>
</template>

<script>
import Common from './common.js'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'message-dialog': MessageDialog
  },
  data() {
    return {
      show: false,
      errors: false,
      showPassword: false,
      showPasswordConfirmation: false,
      password: null,
      passwordConfirmation: null
    }
  },
  methods: {
    showDialog() {
      this.errors = null
      this.password = null
      this.passwordConfirmation = null
      this.show = true
    },
    updateButtonClicked() {
      this.putPassword()
    },
    cancelButtonClicked() {
      this.show = false
    },
    okButtonClicked(tag) {
      if (tag == 'error') {
        return
      }
      this.show = false
    },
    putPassword() {
      this.errors = null
      let params = { password: this.password, password_confirmation: this.passwordConfirmation }
      this.$root.showActivityView()
      Common.axios(document).put('/auth/password', params).then(response => {
        console.log(response)
        this.$root.hideActivityView()
        this.$refs.aMessageDialog.showDialog('Koetatsu Console', '変更しました')
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    }
  }
}
</script>

<style scoped lang="scss">
.password-dialog {
  .a-row {
    margin: 16px 0 32px;
  }
  .update-button {
    width: 96px;
  }
}
</style>

<template>
  <page>
    <div class="settings">
      <div class="a-header">
        <h1>Settings</h1>
      </div>

      <div class="a-setting-content" v-if="setting">

        <div class="a-row" v-if="errors && 0 < errors.length">
          <v-alert :value="errors && 0 < errors.length" color="#E91E63" dark>
            <ul><li v-for="e in errors">{{ e }}</li></ul>
          </v-alert>
        </div>

        <div class="a-row">
          <v-text-field
            v-model="setting.url"
            label="スプレッドシートのURL"
            hint="ex) https://docs.google.com/spreadsheets/d/1SJsHTb1FIiv2__g9rCS6IpGNttRqfU_uD2GPjE7WkdM/edit#gid=0"
            clearable
          />
        </div>
        <div class="a-row">
          <v-text-field
            v-model="setting.sheet_title"
            label="シートのタイトル"
            hint="ex) シート1"
            clearable
          />
        </div>
        <div class="a-row">
          <v-btn large depressed dark color="#E91E63" class="update-button" @click="updateButtonClicked">更新</v-btn>
        </div>

        <p>
          スプレッドシートのURLを変更する場合は、そのスプレッドシートの右上にある共有ボタンから、
          <span>{{ email }}</span>

          <v-tooltip v-model="tooltip" top color="#E91E63">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text small icon @click="copyButtonClicked"><v-icon small>content_copy</v-icon></v-btn>
            </template>
            Copied!
          </v-tooltip>

          を編集者として追加してください。<br>
          また、そのスプレッドシートに設定したタイトルのシートを作成してください。
        </p>

      </div>

      <div class="a-initialize-sheet-content">


        <h3>シート初期化・テスト</h3>
        <p>シートを変更したり、設定を更新した場合は、設定テスト・シート初期化をしてください。</p>
        <v-btn large depressed dark color="#E91E63" class="update-button" @click="initialzieButtonClicked">設定テスト・シート初期化</v-btn>
        <div  v-if="initializeSheet">
          <div class="attr-row first">
            <p>最終更新</p>
            <p />
          </div>
          <div class="attr-row" v-for="attr in initializeSheetAttrs">
            <p class="header">{{ attr.title }}</p>
            <p class="text">{{ attr.text }}</p>
          </div>
        </div>
      </div>


      <message-dialog ref="aMessageDialog" @ok-button-clicked="okButtonClicked" />


    </div>
  </page>
</template>

<script>
import Common from './common.js'
import Page from './page.vue'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'page': Page,
    'message-dialog': MessageDialog
  },
  data() {
    return {
      setting: null,
      email: 'koetatsu-console@koetatsu.iam.gserviceaccount.com',
      tooltip: false,
      errors: null,
      initializeSheet: null,
      tagUpdate: 1,
      tagRun: 2
    }
  },
  computed: {
    initializeSheetAttrs() {
      if (!this.initializeSheet) {
        return
      }
      let states = ['実行まち', '実行中', '成功', '失敗', '他のAPI実行中によりキャンセル']
      return [
        { title: '状態', text: states[this.initializeSheet.state] },
        { title: '更新日時', text: this.initializeSheet.updated_at.split('.')[0].replace('T', ' ') },
        { title: 'スプレッドシートのURL', text: this.initializeSheet.url },
        { title: 'シートのタイトル', text: this.initializeSheet.sheet_title },
        { title: 'エラーメッセージ', text: this.initializeSheet.message }
      ]
    }
  },
  methods: {
    updateButtonClicked() {
      this.updateSetting()
    },
    copyButtonClicked() {
      navigator.clipboard.writeText(this.email)
      this.tooltip = true
      setTimeout(() => {
        this.tooltip = false
      }, 2000)
    },
    initialzieButtonClicked() {
      this.postInitializeSheet()
    },
    okButtonClicked(tag) {
      if (tag == this.tagUpdate) {
        this.getSettings()
        return
      }
      if (tag == this.tagRun) {
        this.getInitializeSheets(this.initializeSheet ? this.initializeSheet.id : 0)
        return
      }
    },
    getSettings() {
      this.$root.showActivityView()
      Common.axios(document).get('/console/settings').then(response => {
        console.log(response)
        this.setting = response.data.setting
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        this.$root.hideActivityView()
      })
    },
    updateSetting() {
      this.errors = null
      let params = {
        setting: {
          url: this.setting.url,
          sheet_title: this.setting.sheet_title
        }
      }
      this.$root.showActivityView()
      Common.axios(document).post('/console/settings', params).then(response => {
        console.log(response)
        this.setting = response.data.setting
        this.$root.hideActivityView()
        this.$refs.aMessageDialog.showDialog('Koetatsu Console', '更新しました!', this.tagUpdate)
      }).catch(error => {
        console.log(error)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    },
    getInitializeSheets(lastId) {
      Common.axios(document).get('/console/initialize_sheets').then(response => {
        console.log(response)
        this.initializeSheet = response.data.initialize_sheet
        if (this.initializeSheet.id == lastId || this.initializeSheet.state < 2) {
          setTimeout(() => {
            this.getInitializeSheets(lastId)
          }, 5000)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    postInitializeSheet() {
      this.$root.showActivityView()
      Common.axios(document).post('/console/initialize_sheets').then(response => {
        this.$root.hideActivityView()
        this.$refs.aMessageDialog.showDialog('Koetatsu Console', '実行しました。', this.tagRun)
      }).catch(error => {
        console.log(error)
        this.errors = error.response.data.errors
        this.$root.hideActivityView()
      })
    }
  },
  mounted() {
    this.getSettings()
    this.getInitializeSheets(0)
  }
}
</script>

<style scoped lang="scss">
.settings {
  .a-header {
    padding: 16px;
  }

  p {
    margin: 0;
    max-width: 1024px;
    line-height: 2em;
  }

  .a-row {
    width: 100%;
    max-width: 1024px;
    padding: 16px 0;
  }

  .a-setting-content {
    padding: 0 16px 16px;

    .a-row {
      .update-button {
        width: 200px;
      }
    }
    p {
      padding: 32px 0;

      span {
        color: #E91E63;
        font-weight: bold;
        display: inline-block;
      }
      .tooltip {
        background-color: #FCE4EC;
        color: #E91E63;
      }
    }
  }

  .a-initialize-sheet-content {
    padding: 16px 16px 32px;

    p {
      padding: 32px 0;
    }

    .attr-row {
      display: flex;
      &.first {
        padding: 48px 0 16px;
      }
      p {
        margin: 0;
        padding: 0 16px 0 0;
        &.header {
          width: 256px;
        }
      }
    }
  }
}
</style>

<template>
  <page>
    <div class="home">
      <h1>Koetatsu Console</h1>
    </div>
  </page>
</template>

<script>
import Page from './page.vue'

export default {
  components: {
    'page': Page
  }
}
</script>

<style scoped lang="scss">
.home {
  padding: 16px;
}
</style>

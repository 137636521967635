<template>
  <div class="activity-view" v-if="show">
    <div>
      <v-progress-circular
        indeterminate
        color="#E91E63"
        :size="88"
        :width="2"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    }
  },
  methods: {
    showView() {
      this.show = true
    },
    hideView() {
      this.show = false
    }
  }
}
</script>

<style scoped>
.activity-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(192, 192, 192, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

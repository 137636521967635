<template>
  <div class="reset-password">
    <div class="reset-password-container">
      <div class="reset-password-content">
        <h1>Reset your password</h1>

        <v-form @submit="onSubmit">

          <div class="a-row">
            <v-alert :value="errors">
              <ul><li v-for="e in errors">{{ e }}</li></ul>
            </v-alert>
          </div>

          <div class="a-row">
            <v-text-field
              v-model="password"
              label="PASSWORD"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              @click:append="showPassword = !showPassword"
              dark
              hide-details
            />
          </div>

          <div class="a-row">
            <v-text-field
              v-model="passwordConfirmation"
              label="PASSWORD CONFIRMATION"
              :type="showPasswordConfirmation ? 'text' : 'password'"
              :append-icon="showPasswordConfirmation ? 'visibility' : 'visibility_off'"
              @click:append="showPasswordConfirmation = !showPasswordConfirmation"
              dark
              hide-details
            />
          </div>

          <div class="a-row">
            <v-btn large depressed type="submit" class="reset-password-button">Submit</v-btn>
          </div>

        </v-form>

        <div>
          <a @click="$router.push('/sign_in')">Back to Sign In</a>
        </div>

        <message-dialog ref="aMessageDialog" @ok-button-clicked="okButtonClicked" />

      </div>
    </div>
  </div>
</template>

<script>
import Common from './common.js'
import MessageDialog from './message_dialog.vue'

export default {
  components: {
    'message-dialog': MessageDialog
  },
  data() {
    return {
      showPassword: false,
      showPasswordConfirmation: false,
      password: null,
      passwordConfirmation: null,
      errors: null
    }
  },
  methods: {
    okButtonClicked() {
      this.$router.push('/')
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.$root.showActivityView()
      Common.axios(document).put('/auth/password', {
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      }).then(response => {
        console.log(response)
        this.$refs.tetraMessageDialog.showDialog('Password Changed Successfully')
        this.$root.hideActivityView()
      }).catch(error => {
        console.log(error)
        console.log(error.response)
        if (error.response.data.errors.full_messages) {
          this.errors = error.response.data.errors.full_messages
        } else {
          this.errors = error.response.data.errors
        }
        this.password = ''
        this.passwordConfirmation = ''
        this.$root.hideActivityView()
      })
    }
  },
  mounted() {
    console.log('mounted')
    Common.saveUid(this.$route.query.uid)
    Common.saveClient(this.$route.query.client)
    Common.saveAccessToken(this.$route.query['access-token'])
  }
}
</script>

<style scoped lang="scss">
.reset-password {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #E91E63;
  color: white;

  .reset-password-container {
    width: 65%;
    margin: 0 0 64px;
    min-width: 360px;

    .reset-password-content {
      width: 100%;
      max-width: 360px;
      padding: 16px;

      .a-row {
        margin: 32px 0;
        .reset-password-button {
          width: 200px;
          color: #E91E63;
        }
      }

      a {
        color: white;
        &:hover, &:visited {
          color: white;
        }
      }
    }
  }
}
</style>
